<template>
  <div class="home">
    <div class="bc2 pt-60 tc">
      <a-radio-group
        size="large"
        v-model="radioType"
        @change="handleChange"
        button-style="solid"
      >
        <a-radio-button value="joinus">
          {{$t('licence.join')}}
        </a-radio-button>
        <a-radio-button value="openProjectandReviewSystem">
          {{$t('licence.openProjectandReviewSystem')}}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div
      class="bc2 pb-150"
      v-show="radioType=='joinus'"
    >
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{$t('licence.join')}}</div>
      </div>
      <div class="p-0-250">
        <img
          v-if="$i18n.locale == 'zh'"
          class="mt-100 mb-100"
          src="@/assets/images/join.png"
          width="100%"
        >
        <img
          v-else
          class="mt-100 mb-100"
          src="@/assets/images/join-en.png"
          width="100%"
        >
        <div class="tc">
          <!-- <span
            style="color:#33B5EE"
            class="f30"
          >沟通项目合作意向</span>
          <span class="f30">发送邮件至</span> -->
          <span class="f30">{{$t('licence.pleaseEmail')}}</span>
          <span
            style="color:#33B5EE"
            class="f50"
          >ncrckd@163.com</span>
        </div>
        <div class="notices bg-color-white">{{$t('licence.notes')}}</div>
      </div>
    </div>
    <div
      class="bc2 pb-150"
      v-show="radioType=='openProjectandReviewSystem'"
    >
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{$t('licence.openProjectandReviewSystem')}}</div>
      </div>
      <div class="p-0-250">
        <img
          v-if="$i18n.locale == 'zh'"
          class="mt-100 mb-100"
          src="@/assets/images/join-1.png"
          width="100%"
        >
        <img
          v-else
          class="mt-100 mb-100"
          src="@/assets/images/join-1-en.png"
          width="100%"
        >
        <div class="flex-dir-col align-center pt-50 pb-50">
          <div class="f30">{{$t('licence.application')}}</div>
          <div class="line"></div>
          <img
            v-if="$i18n.locale == 'zh'"
            class="mt-30 mb-100"
            src="@/assets/images/join-2.png"
            width="100%"
          >
          <img
            v-else
            class="mt-30 mb-100"
            src="@/assets/images/join-2-en.png"
            width="100%"
          >
        </div>
      </div>
      <div class="disflex pro-schedule">
        <div class="vertical"></div>
        <div class="f30">{{$t('licence.download')}}</div>
      </div>
      <div class="p-0-250">
        <div
          class="disflex p20 mt-30 justify-sb"
          style="border: 1px solid #eaeaea;"
        >
          <div>
            <div class="f20 mb-10">
              {{$t('licence.openProjectApplication')}}
            </div>
            <div
              class="disflex f18"
              style="color: #707070"
            >
              <div class="mr-40">
                <a-icon
                  type="clock-circle"
                  class="mr-5"
                />2022-01-01
              </div>
              <div class="mr-40">
                <a-icon
                  type="file"
                  class="mr-5"
                />31KB
              </div>
              <div>
                <a-icon
                  type="file-text"
                  class="mr-5"
                />docx
              </div>
            </div>
          </div>
          <a
            v-if="$i18n.locale=='zh'"
            class="download tc f18"
            href="/static/开放课题申请表.docx"
          >
            <a-icon
              class="mr-5"
              type="download"
            />{{$t('licence.download')}}
          </a>
          <a
            class="download tc f18"
            v-else
            href="/static/Open Project Application Form.docx"
          >
            <a-icon
              class="mr-5"
              type="download"
            />{{$t('licence.download')}}
          </a>
        </div>
        <div
          class="disflex p20 mt-30 justify-sb"
          style="border: 1px solid #eaeaea;"
        >
          <div>
            <div class="f20 mb-10">
              {{$t('licence.openResearchPro')}}
            </div>
            <div
              class="disflex f18"
              style="color: #707070"
            >
              <div class="mr-40">
                <a-icon
                  type="clock-circle"
                  class="mr-5"
                />2021-01-01
              </div>
              <div class="mr-40">
                <a-icon
                  type="file"
                  class="mr-5"
                />29KB
              </div>
              <div>
                <a-icon
                  type="file-text"
                  class="mr-5"
                />docx
              </div>
            </div>
          </div>
          <a
            v-if="$i18n.locale=='zh'"
            class="download tc f18"
            href="/static/中国肾脏病大数据协作网开放课题研究方案.docx"
          >
            <a-icon
              class="mr-5"
              type="download"
            />{{$t('licence.download')}}
          </a>
          <a
            class="download tc f18"
            v-else
            href="/static/Open Research Program.docx"
          >
            <a-icon
              class="mr-5"
              type="download"
            />{{$t('licence.download')}}
          </a>
        </div>
        <div
          class="disflex p20 mt-30 justify-sb"
          style="border: 1px solid #eaeaea;"
        >
          <div>
            <div class="f20 mb-10">
              {{$t('licence.openSubjectMeth')}}
            </div>
            <div
              class="disflex f18"
              style="color: #707070"
            >
              <div class="mr-40">
                <a-icon
                  type="clock-circle"
                  class="mr-5"
                />2021-11-29
              </div>
              <div class="mr-40">
                <a-icon
                  type="file"
                  class="mr-5"
                />213KB
              </div>
              <div>
                <a-icon
                  type="file-text"
                  class="mr-5"
                />docx
              </div>
            </div>
          </div>
          <a
            v-if="$i18n.locale=='zh'"
            class="download tc f18"
            href="/static/中国肾脏病大数据协作网 开放课题管理办法1129.docx"
          >
            <a-icon
              class="mr-5"
              type="download"
            />{{$t('licence.download')}}
          </a>
          <a
            class="download tc f18"
            v-else
            href="/static/Open Subject Management Method.docx"
          >
            <a-icon
              class="mr-5"
              type="download"
            />{{$t('licence.download')}}
          </a>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from "@/components/footer.vue";

export default {
  name: "Home",
  components: {
    myFooter,
  },
  data() {
    return {
      radioType: this.$route.params.type,
    };
  },
  created() {
    this.radioType = this.$route.params.type;
  },
  watch: {
    $route: {
      handler: function (route) {
        this.radioType = route.params.type;
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(e) {
      this.$router.push({ name: "JoinUs", params: { type: e.target.value } });
    },
  },
};
</script>
<style lang="scss" scoped>
.pro-schedule {
  padding: 60px 250px 0px 250px;
}
.p-0-250 {
  padding: 0 250px;
}
.part {
  width: 33%;
}
.vertical {
  width: 8px;
  height: 32px;
  margin-right: 10px;
  background: #33b5ee;
}
.notices {
  padding: 20px 40px;
  border: 1px solid #e7e7e7;
  font-size: 18px;
  margin-top: 60px;
}
.line {
  width: 100px;
  border-bottom: 5px solid #5db2e9;
  margin-bottom: 100px;
}
.download {
  color: #fff;
  width: 120px;
  padding: 5px;
  background-color: #33b5ee;
  margin-left: 50px;
  flex-shrink: 0;
}
</style>
